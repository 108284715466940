<!--
 * @Descripttion:确认专家
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-07 13:41:48
-->
<template>
    <div class="bg vh">
        <van-nav-bar title="专家抽取" left-arrow @click-left="$router.push({name : $route.params.name})"/>

         <div class="pad-row">
            <div class="whiteBg">
                <p><span>项目编号</span><span>20200121</span></p>
                <p><span>项目名称</span><span>项目名称</span></p>
                <p><span>采购单位</span><span>青岛荣耀科技公司</span></p>
                <p><span>评审时间</span><span>2020-11-30  10:30</span></p>
                <p><span>评审地点</span><span>青岛市城阳区双元路16号</span></p>
            </div>
            <div class="whiteBg tt">
                <h3 class="fc" @click="$router.push({name : 'extractionStep22'})">专家一</h3>
                <p>
                    手机号：18888888888
                    <a href="tel:123456789"><i class="icon-phone wh05 mar-l2"></i></a>
                </p>
                <p>是否参与</p>
                <van-radio-group v-model="isJoin" direction="horizontal" checked-color="#00CCB2" class="mar-t2">
                    <van-radio name="1">参加</van-radio>
                    <van-radio name="2">不参加</van-radio>
                </van-radio-group>

                <textarea rows="5" v-model="explain" class="mar-t2 w-100" placeholder="请在这里输入不参与的原因…" v-show="isJoin =='2'"></textarea>
            </div>
         </div>
    </div>
</template>

<script>
import {RadioGroup, Radio } from 'vant'
export default {
    data(){
        return{
            isJoin : '1',
            explain : ''
        }
    },
    components:{
        [Radio.name]:Radio,
        [RadioGroup.name]:RadioGroup
    },
}
</script>

<style scoped>
.whiteBg{padding: 0.2rem 0.4rem; margin-top: 0.4rem;}
.whiteBg>p{display: inline-table; border-bottom: 1px solid #faf5f5; width: 100%;}
.whiteBg>p>span{display: table-cell;padding: 0.1rem 0;}
.whiteBg>p>span:first-child{width: 2rem; font-weight: bold; color: #1E1E1E;}
.whiteBg>p>span:last-child{color: #737373;}
.van-radio--horizontal{margin-right: 1rem;}
.tt>p{border-bottom: none; padding: 0.1rem 0;}
.tt{padding-bottom: 0.5rem;}

</style>
